<template>
  <BCard>
    <BRow>
      <BCol md="3">
        <BFormGroup label="Поиск по Ф.И.О.:">
          <BFormInput
            v-model="filter.search"
            @input="changeFilter('search', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Индивидуальный предприниматель:">
          <BFormInput v-model="filter.individualEnt" />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Поиск по Ф.И.О. СПИ:">
          <BFormInput v-model="filter.nameSpi" />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Поиск по Ф.И.О. поручителя:">
          <BFormInput
            v-model="filter.GUARANTOR__FULL_NAME"
            @input="changeFilter('GUARANTOR__FULL_NAME', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Приоритет:">
          <VSelect
            v-model="filter.priority"
            :options="[]"
            label="name"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Взыскатель:">
          <VSelect
            v-model="filter.CLAIMER_CRM_COMPANY_ID"
            label="COMPANY_NAME"
            :reduce="p =>p.COMPANY_ID"
            :options="debtCompanyListFilter.results"
            @input="changeFilter('CLAIMER_CRM_COMPANY_ID', $event)"
          />
        </BFormGroup>
        <!--        :options="debtCompanyList.results"-->
      </BCol>
      <BCol md="3">
        <BFormGroup label="Дело отложено:">
          <VSelect
            v-model="filter.delayed"
            label="name"
            :options="[]"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="VIP должник:">
          <VSelect
            v-model="filter.vip"
            label="name"
            :options="[]"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Регион:">
          <VSelect
            v-model="filter.region"
            label="name"
            :options="[]"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Дата рождения (с - по):">
          <VcDatePicker
            ref="birthdayRef"
            v-model="birthday"
            show-format="DD.MM.YYYY"
            :other-props="{ 'is-range': true }"
            @input="changeFilter('birthday', $event)"
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <label
          for=""
          class="col-form-label pt-0"
        > Сумма с по </label>
        <div class="d-flex">
          <!-- :formatter="numFormatter" -->
          <BFormInput
            v-model="filter.DEBT_SUM_FROM"
            class="w-50"
            @input="changeFilter('DEBT_SUM_FROM', $event)"
          />
          <div><b style="line-height: 2.5rem">—</b></div>
          <BFormInput
            v-model="filter.DEBT_SUM_TO"
            class="w-50"
            @input="changeFilter('DEBT_SUM_TO', $event)"
          />
        </div>
      </BCol>
      <BCol md="3">
        <BFormGroup label="Банкрот:">
          <VSelect
            v-model="filter.bankrupt"
            :options="[]"
            label="name"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <BCollapse
      id="collapse-cases-filter"
      v-model="collapseFilterState"
    >
      <BRow>
        <BCol md="3">
          <BFormGroup label="Умерший должник:">
            <VSelect
              v-model="filter.DATE_OF_DEATH"
              label="title"
              :options="DATE"
              :reduce="p => p.value"
              @input="changeFilter('DATE_OF_DEATH', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Статус ИД:">
            <VSelect
              v-model="filter.statusId"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Стадия:">
            <VSelect
              v-model="filter.DO_STAGE"
              label="title"
              :reduce="(p) => p.value"
              :options="DO_STAGE"
              @input="changeFilter('DO_STAGE', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Вид дохода:">
            <VSelect
              v-model="filter.incomeType"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Кейс:">
            <VSelect
              v-model="filter.case"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Правопреемство:">
            <VSelect
              v-model="filter.right"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Группировка:">
            <VSelect
              v-model="filter.group"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Наличие платежей:">
            <VSelect
              v-model="filter.payments"
              :options="[]"
              label="name"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Принадлежность:">
            <VSelect
              v-model="filter.BASIS__BELONGING"
              :options="belongingOptions"
              label="title"
              :reduce="item => item.value"
              @input="changeFilter('BASIS__BELONGING', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Ответственный менеджер:">
            <VSelect
              v-model="filter.MANAGER"
              :options="userList.results"
              label="full_name"
              :reduce="el => el.id"
              @input="changeFilter('MANAGER', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Дата правопреемства (с - по):">
            <VcDatePicker
              v-model="filter.date"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Платежи (с - по):">
            <VcDatePicker
              ref="paymentsRef"
              v-model="payments"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
              @input="changeFilter('payments', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Последний платёж (с - по):">
            <VcDatePicker
              v-model="filter.lastPayment"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Дата последнего действия (с - по):">
            <VcDatePicker
              ref="last_action"
              v-model="last_action"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
              @input="changeFilter('last_action', $event)"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Срок просрочки (с - по):">
            <VcDatePicker
              v-model="filter.overdue"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
            />
          </BFormGroup>
        </BCol>
        <BCol md="3">
          <BFormGroup label="Дата продажи (с - по):">
            <VcDatePicker
              v-model="filter.dateOfSale"
              show-format="DD.MM.YYYY"
              :other-props="{ 'is-range': true }"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCollapse>
    <BRow>
      <BCol>
        <BButton
          class="w-100"
          :variant="collapseFilterState ? 'primary' : 'light'"
          @click="collapseFilterState = !collapseFilterState"
        >
          {{ getFilterCollapse }}
        </BButton>
      </BCol>
    </BRow>

    <BRow class="mt-2">
      <BCol md="6">
        <BButton variant="outline-success">
          <feather-icon
            icon="ListIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ debtCompanyList.count }}</span>
        </BButton>
      </BCol>
      <BCol
        md="6"
        class="d-flex justify-content-end"
      >
        <BButton
          class="border mr-1"
          variant="light"
          @click="clearFilter"
        >
          Сбросить фильтр
        </BButton>
        <BButton
          class="d-flex align-items-center"
          variant="success"
          @click="$emit('exportExcel')"
        >
          Скачать в Excel
          <b-spinner
            v-if="loading"
            style="width: 1.5rem; height: 1.5rem"
            class="ml-50"
          />
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BCollapse,
  BSpinner,
} from 'bootstrap-vue'

import VSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import saveFilter from '@/mixins/saveFilter'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCollapse,
    VSelect,
    BSpinner,
  },
  mixins: [saveFilter],
  props: {
    filter: {
      type: Object,
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      all: {
        COMPANY_NAME: 'Все КПК',
        COMPANY_ID: 'all',
      },
      collapseFilterState: false,
      birthday: {
        start: null,
        end: null,
      },
      payments: {
        start: null,
        end: null,
      },
      last_action: {
        start: null,
        end: null,
      },
      belongingOptions: [
        {
          title: 'Купленный',
          value: 'bought',
        },
        {
          title: 'Агентский',
          value: 'agency',
        },
      ],
    }
  },
  computed: {
    // debtCompanyList
    ...mapState('cases', ['DO_STAGE', 'DATE']),
    ...mapState('users', ['userList']),
    ...mapState('notProspective', ['debtCompanyList', 'debtCompanyListFilter']),

    getFilterCollapse() {
      if (this.collapseFilterState) {
        return 'Показать меньше фильтров'
      }
      return 'Показать все фильтры'
    },
  },
  async created() {
    const filter = { ...this.$_getFilter() }

    this.birthday.start = filter.BIRTH_DATE__FROM
    this.birthday.end = filter.BIRTH_DATE__TO
    this.payments.start = filter.PAY_DATE_FROM
    this.payments.end = filter.PAY_DATE_TO
    this.last_action.start = filter.LAST_ACTION_DATE_FROM
    this.last_action.end = filter.LAST_ACTION_DATE_TO

    try {
      await this.FETCH_SK_DEBT_COMPANY_LIST_FILTER({ page_size: 1000 })
      // await this.FETCH_SK_DEBT_COMPANY_LIST({ page_size: 1000 })
      this.debtCompanyListFilter.results.unshift(this.all)
      await this.FETCH_ALL_USERS({ page_size: 500, role_unique_name: 'dk' })
    } catch (error) {
      await this.$_errorToast()
    }
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT_COMPANY_LIST']),
    ...mapActions('users', ['FETCH_ALL_USERS']),
    ...mapActions('notProspective', ['FETCH_SK_DEBT_COMPANY_LIST_FILTER']),

    changeFilter(type, value) {
      this.$emit('changeFilter', type, value)
    },

    refresh() {
      this.$emit('refresh')
    },

    clearFilter() {
      this.$emit('clearFilter')
      this.birthday = {
        start: null,
        end: null,
      }
      this.payments = {
        start: null,
        end: null,
      }
      this.last_action = {
        start: null,
        end: null,
      }
      // this.refresh()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
