<template>
  <b-overlay
    :show="reFetch"
    rounded="sm"
  >
    <BTable
      class="table__pass"
      :busy="reFetch"
      :items="debtCompanyList.results"
      :fields="fields"
      show-empty
      responsive
    >
      <template #empty>
        <empty />
      </template>

      <template #cell(pay_his)="props">
        <div v-if="props.item.BASIS">
          <div
            v-for="(e, index) in props.item.BASIS.PAY_HISTORY"
            :key="index"
          >
            <ul
              class="d-flex align-items-center justify-content-between mb-0"
            >
              <li style="font-size: 11px">
                {{ e.PAY_DATE }}
              </li>
              <small class="ml-50">
                {{ $_moneyFormatter(e.PAY_AMOUNT) }},
              </small>
            </ul>
          </div>
        </div>
      </template>
      <template #cell(MANAGER)="props">
        <v-select
          v-model="props.item.MANAGER"
          label="full_name"
          :options="userList.results"
          @input="manager(props.item)"
        >
          <template #no-options>
            К сожалению, нет подходящих вариантов
          </template>
        </v-select>
      </template>

      <template #cell(debtors)="data">
        <router-link
          v-if="data.item.BORROWER"
          :to="`/case-card/${data.item.id}`"
        >
          {{ data.item.BORROWER.FULL_NAME }}
        </router-link>
      </template>

      <template #cell(BELONGING)="{ item }">
        <div v-if="item.BASIS">
          <v-select
            v-model="item.BASIS.BELONGING"
            label="title"
            style="width: 170px"
            :options="belongingOptions"
            :reduce="item => item.value"
            @input="changeBelonging($event, item.id)"
          >
            <template #no-options>
              К сожалению, нет подходящих вариантов
            </template>
          </v-select>
        </div>
      </template>

      <template #cell(BASIS)="props">
        <div v-if="props.item.BASIS">
          <span v-if="props.item.BASIS.DO_STAGE === 'voluntary_payment'">Добровольная оплата</span>
          <span v-else-if="props.item.BASIS.DO_STAGE === 'claim'">Претензия</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'judical_work'">Судебная работа</span>
          <span v-else-if="props.item.BASIS.DO_STAGE === 'executive_document'">Исполнительный документ</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'executive_work'">Исполнительное производство</span>
          <span v-else-if="props.item.BASIS.DO_STAGE === 'stopped'">Приостановлено</span>
          <span v-if="props.item.BASIS.DO_STAGE === 'paid'">Погашен</span>
        </div>
      </template>
      <template #cell(BASISDEBT)="props">
        <span v-if="props.item.BASIS"> {{ $_moneyFormatter(props.item.BASIS.DEBT) }}</span>
      </template>
      <template #cell(EXECUTIVE_DOC_STATUS)="props">
        <b-badge
          v-if="props.item.BASIS"
          variant="primary"
        >
          {{ props.item.BASIS.EXECUTIVE_DOC_STATUS }}
        </b-badge>
      </template>
    </BTable>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import {
  BTable, VBTooltip, BOverlay, BBadge,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import empty from '@/components/empty.vue'
import FIELDS from './fields'

export default {
  components: {
    BBadge,
    BOverlay,
    BTable,
    VSelect,
    empty,
  },
  props: {
    reFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actionDataMany: {
        forAll: false,
        responsible: null,
        otherKey: null,
      },
      belongingOptions: [
        {
          title: 'Купленный',
          value: 'bought',
        },
        {
          title: 'Агентский',
          value: 'agency',
        },
      ],
      fields: FIELDS.fields,
    }
  },
  computed: {
    ...mapState('notProspective', ['debtCompanyList']),
    ...mapState('users', ['userList']),
  },
  mounted() {
    // this.FETCH_ALL_USERS({ page_size: 500 })
  },
  methods: {
    ...mapActions('cases', ['FETCH_SK_DEBT', 'UPDATE_SK_DEBT_MANAGER', 'UPDATE_SK_DEBT_ZAYM']),
    ...mapActions('users', ['FETCH_ALL_USERS']),

    async changeBelonging(event, id) {
      try {
        await this.UPDATE_SK_DEBT_ZAYM({
          id,
          BELONGING: event,
        })
        await this.$_okToast()
      } catch (error) {
        await this.$_errorToast()
      }
    },

    manager(props) {
      if (props) {
        this.UPDATE_SK_DEBT_MANAGER({
          id: props.id,
          MANAGER: props.MANAGER ? props.MANAGER.id : null,
        })
          .then(() => {
            this.FETCH_SK_DEBT()
          })
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.w_g {
  width: 250px
}

.table__pass td {
  vertical-align: top;
}
</style>
